<template>
<div class="balancecheck-container" id="js_balancecheck_container">
	<article class="balancecheck autonomic-nerves">
		<h1 class="balancecheck__header">
			<img src="@/assets/img/balancecheck-autonomic-nerves/title01.png" alt="医師監修 自律神経のバランスチェック">
			<p><span>presented by</span><i class="balancecheck__header--icon sprite_autonomic-nerves logo" /></p>
		</h1>

		<!-- 紹介 -->
		<section class="balancecheck__intro">
			頭痛や肩こりがつらい、だるい、めまいがする…など、たえず不調に悩まされていませんか？<br>もしかするとそれは「自律神経」のせいかもしれません。日常生活を振り返り、以下の質問に答えて確認してみましょう。（質問は12問あります。）
		</section>
		<!-- /紹介 -->

		<!-- 自律神経バランスチェック -->
		<section class="balancecheck__questions">
			<ul class="balancecheck__questions--list">
				<li class="balancecheck__questions--item">
					<span class="balancecheck__questions--heading">Q.1</span>
					<p class="balancecheck__questions--text">朝起きるのがつらく<br>目覚めがすっきりしない</p>
					<div class="balancecheck__questions--label">
						<label>
							<input type="radio" name="q01" :value="1" v-model="question[0]">
							<i class="balancecheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q01" :value="0" v-model="question[0]">
							<i class="balancecheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="balancecheck__questions--item">
					<span class="balancecheck__questions--heading">Q.2</span>
					<p class="balancecheck__questions--text">眠れない、または寝つきが悪く<br>寝ても疲れが取れにくい</p>
					<div class="balancecheck__questions--label">
						<label>
							<input type="radio" name="q02" :value="1" v-model="question[1]">
							<i class="balancecheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q02" :value="0" v-model="question[1]">
							<i class="balancecheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="balancecheck__questions--item">
					<span class="balancecheck__questions--heading">Q.3</span>
					<p class="balancecheck__questions--text">いつも疲労感やだるさがあり<br>調子が悪い</p>
					<div class="balancecheck__questions--label">
						<label>
							<input type="radio" name="q03" :value="1" v-model="question[2]">
							<i class="balancecheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q03" :value="0" v-model="question[2]">
							<i class="balancecheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="balancecheck__questions--item">
					<span class="balancecheck__questions--heading">Q.4</span>
					<p class="balancecheck__questions--text">慢性的に<br>首コリ肩コリがある</p>
					<div class="balancecheck__questions--label">
						<label>
							<input type="radio" name="q04" :value="1" v-model="question[3]">
							<i class="balancecheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q04" :value="0" v-model="question[3]">
							<i class="balancecheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="balancecheck__questions--item">
					<span class="balancecheck__questions--heading">Q.5</span>
					<p class="balancecheck__questions--text">頭痛やめまいが<br>起きやすい</p>
					<div class="balancecheck__questions--label">
						<label>
							<input type="radio" name="q05" :value="1" v-model="question[4]">
							<i class="balancecheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q05" :value="0" v-model="question[4]">
							<i class="balancecheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="balancecheck__questions--item">
					<span class="balancecheck__questions--heading">Q.6</span>
					<p class="balancecheck__questions--text">胃痛、胃もたれ、吐き気、食欲増減、<br>下痢、便秘など胃腸の不調がある</p>
					<div class="balancecheck__questions--label">
						<label>
							<input type="radio" name="q06" :value="1" v-model="question[5]">
							<i class="balancecheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q06" :value="0" v-model="question[5]">
							<i class="balancecheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="balancecheck__questions--item">
					<span class="balancecheck__questions--heading">Q.7</span>
					<p class="balancecheck__questions--text">のどが詰まっているように感じる<br>など違和感がある</p>
					<div class="balancecheck__questions--label">
						<label>
							<input type="radio" name="q07" :value="1" v-model="question[6]">
							<i class="balancecheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q07" :value="0" v-model="question[6]">
							<i class="balancecheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="balancecheck__questions--item">
					<span class="balancecheck__questions--heading">Q.8</span>
					<p class="balancecheck__questions--text">動悸や胸のざわつき、不安感、<br>息苦しさがある</p>
					<div class="balancecheck__questions--label">
						<label>
							<input type="radio" name="q08" :value="1" v-model="question[7]">
							<i class="balancecheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q08" :value="0" v-model="question[7]">
							<i class="balancecheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="balancecheck__questions--item">
					<span class="balancecheck__questions--heading">Q.9</span>
					<p class="balancecheck__questions--text">イライラ、モヤモヤしやすく<br>気分が落ち着かない</p>
					<div class="balancecheck__questions--label">
						<label>
							<input type="radio" name="q09" :value="1" v-model="question[8]">
							<i class="balancecheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q09" :value="0" v-model="question[8]">
							<i class="balancecheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="balancecheck__questions--item">
					<span class="balancecheck__questions--heading">Q.10</span>
					<p class="balancecheck__questions--text">パソコンやスマートフォンを<br>長時間使用する</p>
					<div class="balancecheck__questions--label">
						<label>
							<input type="radio" name="q10" :value="1" v-model="question[9]">
							<i class="balancecheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q10" :value="0" v-model="question[9]">
							<i class="balancecheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="balancecheck__questions--item">
					<span class="balancecheck__questions--heading">Q.11</span>
					<p class="balancecheck__questions--text">運動（筋トレを除く）を<br>あまりしない</p>
					<div class="balancecheck__questions--label">
						<label>
							<input type="radio" name="q11" :value="1" v-model="question[10]">
							<i class="balancecheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q11" :value="0" v-model="question[10]">
							<i class="balancecheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="balancecheck__questions--item">
					<span class="balancecheck__questions--heading">Q.12</span>
					<p class="balancecheck__questions--text">天気（気圧・寒暖・湿度）の影響で<br>不調が起きやすい</p>
					<div class="balancecheck__questions--label">
						<label>
							<input type="radio" name="q12" :value="1" v-model="question[11]">
							<i class="balancecheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q12" :value="0" v-model="question[11]">
							<i class="balancecheck__questions--button" />
						</label>
					</div>
				</li>
			</ul>
		</section>
		<!-- /自律神経バランスチェック -->

		<!-- 結果をみる -->
		<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=balancecheckAutonomicNervesRes`, _result: String(computedRequltParam), _answer: computedAnswerParam } })" id="trackingBalancecheckResultBtn01" :class="[
			'balancecheck__button',
			{ 'is-eabled': computedEabledflag }
		]">結果をみる</a>
		<!-- /結果をみる -->

		<!-- 監修者 -->
		<section class="balancecheck__supervisor">
			<h2 class="balancecheck__supervisor--img"><img src="@/assets/img/balancecheck-autonomic-nerves/img01.png" alt="面白いほどわかる 自律神経の新常識"></h2>
			<div class="balancecheck__supervisor--inner">
				<p class="balancecheck__supervisor--title"><span class="is-small">TJ MOOK</span><br>面白いほどわかる<br>自律神経の新常識</p>
				<p class="balancecheck__supervisor--text">著者：久手堅 司（監修）<br>出版社：宝島社</p>
			</div>
		</section>
		<!-- /監修者 -->

		<section class="balancecheck__outro">
			<a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="about-rightnow__login--button">TOPにもどる</a>
		</section>

		<!-- 特集にもどる -->
		<!-- <a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'lpautonomicnerves2021' } })" class="balancecheck__button is-back">自律神経の<br>バランスチェック特集にもどる</a> -->
		<!-- /特集にもどる -->
	</article>
</div>
</template>

<script>
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'BalancecheckAutonomicNerves',
	components: {
	},
	data () {
		return {
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			backendUrl: cmnConst.BACKEND_URL,
			question: []
		}
	},
	props: {
	},
	computed: {
		// 結果を算出する
		computedRequltParam () {
			const resultParam = this.question.reduce((accumulator, currentValue) => {
				return accumulator + currentValue
			}, 0)
			return resultParam >= 9 ? 2 : resultParam >= 5 ? 1 : 0
		},

		// どの設問を〇にしたかを算出する
		computedAnswerParam () {
			return this.question.map((value, index) => {
				return value ? index + 1 : 0
			}).filter(item => item !== 0).join(',')
		},

		// すべての設問を選択したかを算出する
		computedEabledflag () {
			return this.question.filter(item => typeof item !== 'undefined').length === 12
		}
	},
	mounted () {
		// コンテンツの高さをemitする処理
		// CHANGED: コンテンツの高さを確保する処理の見直しのためコメントアウト
		// this.$nextTick(() => {
		// 	setTimeout(() => {
		// 		const domContainer = document.getElementById('js_balancecheck_container')
		// 		if (domContainer) this.$emit('containerHeight', domContainer.clientHeight)
		// 	}, 1000)
		// })
	},
	beforeDestroy () {
	},
	methods: {
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

// NOTE: このページ限定の変数（デザインシステム外のため他での利用禁止）
$spacing-12: 0.75rem;
$spacing-13: 0.812rem;
$spacing-14: 0.875rem;
$spacing-15: 0.937rem;
$spacing-22: 1.375rem;
$spacing-26: 1.625rem;
$spacing-28: 1.75rem;
$spacing-38: 2.375rem;
$spacing-40: 2.5rem;
$font-size-15: 0.937rem;
$font-size-19: 1.187rem;
$font-size-36: 2.25rem;
$balancecheck-primary: #FC7053;
$balancecheck-submit-background: #FF6140;
$balancecheck-submit-border: #EF411D;
$balancecheck-submit-disabled-border: #999999;
$balancecheck-back-background: #34ABD1;
$balancecheck-back-border: #2091B5;
$line-height-13: 1.3;

.balancecheck {
	padding: 0;

	&__intro {
		font-family: $lp-family-primary;
		margin: $spacing-20 0 0 0;
		padding: 0 $spacing-14;
		font-size: $font-size-15;
		text-align: justify;
		color: $text-primary;
	}

	&__questions {
		$parent: &;
		width: 92.5334%;
		margin: $spacing-22 auto 0;

		&--list {
			margin: 0;
			padding: 0;
		}

		&--item {
			margin-top: $spacing-26;
			padding: $spacing-15 $spacing-20 $spacing-28;
			list-style: none;
			font-family: $lp-family-secondary;
			border: 2px solid $balancecheck-primary;
			border-radius: 10px;

			&:first-of-type { margin: 0; }
		}

		&--heading {
			display: block;
			color: $balancecheck-primary;
			font-size: $font-size-36;
			font-weight: bold;
			text-align: center;
			border-bottom: 1px dashed $balancecheck-primary;
		}

		&--text {
			margin: $spacing-15 0 0;
			text-align: center;
			font-size: $font-size-18;
			white-space: nowrap;

			@media (max-width: (360px)) {
				font-size: $font-size-16;
			}
		}

		&--label {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: $spacing-12;

			label {
				input[type="radio"] {
					display: none;
				}

				input[type="radio"] + #{$parent}--button {
					background-image: url(~@/assets/img/balancecheck-autonomic-nerves/sprite_inp.png);
					background-size: (260px / 2) (360px / 2);
					background-repeat: no-repeat;
					display: block;
					background-position: 0 0;
					width: (260px / 2);
					height: (80px / 2);
					@media (max-width: (360px)) {
						background-size: (220px / 2) (305px / 2);
						width: (220px / 2);
						height: (68px / 2);
					}
				}

				&:nth-of-type(odd) {
					input[type="radio"] + #{$parent}--button {
						background-position: 0 0;
					}

					input[type="radio"]:checked + #{$parent}--button {
						background-position: 0 (-80px / 2);
						@media (max-width: (360px)) {
							background-position: 0 (-68px / 2);
						}
					}
				}

				&:nth-of-type(even) {
					margin-left: $spacing-20;

					input[type="radio"] + #{$parent}--button {
						background-position: 0 (-160px / 2);
						@media (max-width: (360px)) {
							background-position: 0 (-135px / 2);
						}
					}

					input[type="radio"]:checked + #{$parent}--button {
						background-position: 0 (-240px / 2);
						@media (max-width: (360px)) {
							background-position: 0 (-203px / 2);
						}
					}
				}
			}
		}
	}

	&__button {
		display: block;
		width: 80%;
		margin: $spacing-38 auto 0;
		padding: $spacing-13 0;
		box-sizing: border-box;
		background: $button-secondary;
		border-radius: 6px;
		text-align: center;
		color: $text-seconday;
		font-weight: bold;
		font-size: $font-size-24;
		box-shadow: 0 3px $balancecheck-submit-disabled-border;
		pointer-events: none;

		@media (max-width: (374px)) { font-size: $font-size-20; }
		@media (max-width: (330px)) { font-size: $font-size-18; }

		&.is-eabled {
			background: $balancecheck-submit-background;
			box-shadow: 0 3px $balancecheck-submit-border;
			pointer-events: auto;
		}

		&.is-back {
			padding: $spacing-10 0;
			background: $balancecheck-back-background;
			box-shadow: 0 3px $balancecheck-back-border;
			pointer-events: auto;
			font-size: $font-size-19;
			line-height: $line-height-13;

			@media (max-width: (374px)) { font-size: $font-size-18; }
			@media (max-width: (330px)) { font-size: $font-size-16; }
		}
	}

	&__supervisor { margin: $spacing-40 auto 0; }

	.sprite_autonomic-nerves {
		background-image: url(~@/assets/img/balancecheck-autonomic-nerves/sprite_inp.png);
		background-size: (260px / 2) (360px / 2);
		background-repeat: no-repeat;
		display: block;

		&.logo {
			width: (157px / 2);
			height: (40px / 2);
			background-position: 0 (-320px / 2);
		}
	}

	&__outro {
		margin-top: $spacing-40;
	}
}
</style>
